<template>
  <div>
    <h1 class="mb-0">{{ $t('auth.reset_password') }}</h1>
    <p>{{ $t('auth.reset_password_text') }}</p>
    <div class="form-group">
      <label for="exampleInputEmail1">{{ $t('auth.email_address') }}</label>
      <input type="email" class="form-control mb-0" v-model="email" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
    </div>

    <div v-if="!isActive" class="d-inline-block w-100">
      <button @click="submitPassword" class="btn btn-primary float-right">{{ $t('auth.reset_password') }}</button>
    </div>

    <div v-if="isActive">{{ $t('activate.email_recover') }}</div>
    <div class="text-right">
      <router-link to="/auth/login" class="iq-waves-effect pr-4 text-right">
        {{ $t('activate.login') }}
      </router-link>
    </div>
  </div>
</template>
<script>
import Axios from 'axios'
import { API } from '@/config/api.config'
import { core } from '@/config/pluginInit'

export default {
  data () {
    return {
      isActive: false,
      email: ''
    }
  },
  name: 'RecoverPassword1',
  methods: {
    submitPassword () {
      const regex = /\S+@\S+\.\S+/
      if (this.email === '') {
        core.showSnackbar('error', this.$t('activate.email_mandatory'))
        return 0
      } else if (!regex.test(this.email)) {
        core.showSnackbar('error', this.$t('activate.email_type'))
        return 0
      }
      const dataPost = {
        email: this.email
      }
      Axios.post(API.API_RECOVER_PASSWORD, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.isActive = true
          } else {
            this.isActive = false
          }
        })
        .catch((error) => {
          core.showSnackbar('error', error.response)
        })
    }
  }
}
</script>
